<template>
  <div>
    <!-- <vs-button @click="getviracloud"> tets </vs-button> -->
    <vx-card class="my-5">
      <vs-divider>Filter by</vs-divider>
      <vs-row>
        <vs-col vs-w="3" vs-sm="4" vs-xs="6">
          <vs-input
            v-model="Vm_title"
            class="mx-1 mx-3"
            label-placeholder="VM name"
            @keyup.enter="getviracloud()"
          ></vs-input>
        </vs-col>
        <vs-col vs-w="3" vs-sm="4" vs-xs="6">
          <vs-input
            v-model="Vm_status"
            class="mx-1 mx-3"
            label-placeholder="VM status"
            @keyup.enter="getviracloud()"
          ></vs-input>
        </vs-col>
        <vs-col vs-w="3" vs-sm="4" vs-xs="6">
          <vs-input
            v-model="User_id"
            class="mx-1 mx-3"
            label-placeholder="User ID"
            @keyup.enter="getviracloud()"
          ></vs-input>
        </vs-col>
        <vs-col vs-w="3" vs-sm="4" vs-xs="6">
          <vs-input
            v-model="Id"
            class="mx-1 mx-3"
            label-placeholder="VM ID"
            @keyup.enter="getviracloud()"
          ></vs-input>
        </vs-col>
        <vs-col vs-w="3" vs-sm="4" vs-xs="6">
          <vs-input
            v-model="Vpc_id"
            class="mx-1 mx-3"
            label-placeholder="VPC ID"
            @keyup.enter="getviracloud()"
          ></vs-input>
        </vs-col>
        <vs-col vs-w="3" vs-sm="4" vs-xs="6">
          <vs-input
            v-model="Vm_number"
            class="mx-1 mx-3"
            label-placeholder="VM number"
            @keyup.enter="getviracloud()"
          ></vs-input>
        </vs-col>
        <vs-col vs-w="3" vs-sm="4" vs-xs="6">
          <vs-select
            v-model="Is_suspend"
            class="my-5 mx-3"
            placeholder="مسدودیت"
            @change="getviracloud()"
          >
            <vs-select-item
              v-for="(item, index) in options"
              :key="index"
              :value="item.value"
              :text="item.text"
            />
          </vs-select>
        </vs-col>
        <vs-button
          class="w-full my-5"
          size="large"
          icon="search"
          @click="getviracloud()"
        >
        </vs-button>
      </vs-row>
    </vx-card>
    <vx-card>
      <vs-button
        type="border"
        :color="wantstodelte ? '#900' : ''"
        size="small"
        @click="wantstodelte = !wantstodelte"
        >delete</vs-button
      >
      <vs-pagination
        class="my-3"
        :total="page_count"
        v-model="page"
      ></vs-pagination>
      <!-- <vs-button class="my-3" @click="getviracloud">click me</vs-button> -->
      <vs-table
        @dblSelection="doubleSelection"
        v-model="selectedviracloud"
        :data="viracloudinfo"
      >
        <template slot="header"></template>
        <template slot="thead">
          <vs-th>VM name</vs-th>
          <vs-th>VM Status</vs-th>
          <vs-th>user ID</vs-th>
          <vs-th>Vm ID</vs-th>
          <vs-th>Vpc ID</vs-th>
          <vs-th>Vm number</vs-th>
          <vs-th>Subnet ID</vs-th>
          <vs-th>Vm type</vs-th>
          <vs-th>Vm template</vs-th>
          <vs-th>Public IP</vs-th>
          <vs-th>Suspend</vs-th>
          <vs-th>Created At</vs-th>
          <vs-th v-if="wantstodelte"> REMOVE </vs-th>
        </template>

        <template slot-scope="{ data }">
          <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
            <vs-td :data="data[indextr].vm_title">
              {{ data[indextr].vm_title }}
            </vs-td>
            <vs-td :data="data[indextr].vm_status">
              <span v-if="data[indextr].vm_status == 1"
                ><p class="text-warning">
                  {{ data[indextr].vm_status }}
                </p>
                در حال ساخت</span
              >
              <span v-if="data[indextr].vm_status == 2"
                ><p class="text-warning">
                  {{ data[indextr].vm_status }}
                </p>
                درحال ساخت</span
              >
              <span v-if="data[indextr].vm_status == 3"
                ><p class="text-warning">
                  {{ data[indextr].vm_status }}
                </p>
                درحال ساخت</span
              >
              <span v-if="data[indextr].vm_status == 4"
                ><p class="text-success">
                  {{ data[indextr].vm_status }}
                </p>
                آنلاین</span
              >
              <span v-if="data[indextr].vm_status == 6"
                ><p class="text-danger">
                  {{ data[indextr].vm_status }}
                </p>
                خاموش</span
              >
            </vs-td>
            <vs-td :data="data[indextr].vm_id">
              {{ data[indextr].user_id }}
            </vs-td>
            <vs-td :data="data[indextr].vm_id">
              {{ data[indextr].id }}
            </vs-td>
            <vs-td :data="data[indextr].vpc_id">
              {{ data[indextr].vpc_id }}
            </vs-td>
            <vs-td :data="data[indextr].vm_name">
              {{ data[indextr].vm_name }}
            </vs-td>
            <vs-td :data="data[indextr].subnet_id">
              {{ data[indextr].subnet_id }}
            </vs-td>
            <vs-td :data="data[indextr].subject">
              {{ data[indextr].vm_type == 1 ? "LXC" : "QEMU" }}
            </vs-td>
            <vs-td :data="data[indextr].subject">
              {{
                `${data[indextr].template_title} - ${data[indextr].template_version}`
              }}
            </vs-td>
            <vs-td :data="data[indextr].subject">
              {{ data[indextr].public_ip ? data[indextr].public_ip : "none" }}
            </vs-td>
            <vs-td
              :class="data[indextr].is_suspend ? 'text-danger' : 'text-success'"
              :data="data[indextr].subject"
            >
              {{ data[indextr].is_suspend ? "متوقف شده" : "متوقف نشده" }}
            </vs-td>
            <vs-td v-if="wantstodelte" :data="data[indextr].remove">
              <vs-button
                size="small"
                color="#900"
                type="filled"
                @click="
                  deleteviracloud({
                    vm_id: data[indextr].id,
                    vm_number: data[indextr].vm_name,
                  })
                "
                >Delete</vs-button
              >
            </vs-td>
            <vs-td :data="data[indextr].subject">
              {{ data[indextr].created_at }}
            </vs-td>
          </vs-tr>
        </template>
      </vs-table>
      <vs-pagination
        class="my-3"
        :total="page_count"
        v-model="page"
      ></vs-pagination>
      <p>{{ selectedviracloud }}</p>
      <vue-json-pretty :data="selectedviracloud"> </vue-json-pretty>
    </vx-card>
  </div>
</template>
<script>
import VueJsonPretty from "vue-json-pretty";
import "vue-json-pretty/lib/styles.css";
export default {
  components: {
    VueJsonPretty,
  },
  data() {
    return {
      //============= Pagination ===============
      page_count: 0,
      page: 1,
      //============= Pagination ===============
      viracloudinfo: [],
      selectedviracloud: null,
      wantstodelte: false,
      //nullable
      Id: "",
      //nullable
      Vpc_id: "",
      //nullable
      User_id: "",
      //nullable
      Vm_number: "",
      //nullable
      Vm_title: "",
      //nullable, 1-6
      Vm_status: "",
      // is_suspend, suspend = 1
      Is_suspend: "",
      // ============== other ==================
      options: [
        { text: "مسدود شده", value: "1" },
        { text: "همه", value: "0" },
      ],
      // ============== other ==================
    };
  },
  watch: {
    // selectedviracloud() {
    //   // console.log(this.selectedviracloud);
    // },
    page() {
      this.getviracloud({ page: this.page });
    },
  },
  methods: {
    doubleSelection() {
      // console.log(this.selectedviracloud);
      this.$router.push(`/user?id=${this.selectedviracloud.user_id}`);
    },
    getviracloud() {
      const data = {
        id: this.Id,
        vpc_id: this.Vpc_id,
        user_id: this.User_id,
        vm_number: this.Vm_number,
        vm_title: this.Vm_title,
        vm_status: this.Vm_status,
        is_suspend: this.Is_suspend,
        page: this.page,
      };
      // console.log(data);
      this.viracloudinfo = [];
      this.$vs.loading();
      this.$http
        .post("admin/vira/cloud/vm/list", data)
        .then((res) => {
          this.$vs.loading.close();
          // console.log(res);
          // console.log(res.data.params.data);
          this.page_count = res.data.params.last_page;
          this.viracloudinfo = res.data.params.data;
        })
        .catch((err) => {
          this.$vs.loading.close();
          console.log(err);
        });
    },
    deleteviracloud(data) {
      console.log(
        `THE VM WITH INFORMATION {vm_number :${data.vm_number}, vm_id: ${data.vm_id}}\n HAS BEEN DELETED`
      );
      this.$http
        .post("admin/vira/cloud/vm/remove", data)
        .then((result) => {
          this.$vs.notify({ text: result.data.message, color: "success" });
        })
        .catch((err) => {
          this.$vs.notify({ text: err.response.data.message, color: "danger" });
        });
      this.getviracloud();
    },
  },
  created() {
    this.getviracloud();
  },
};
</script>
